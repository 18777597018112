<bracelit-form [bracelitFormGroup]="myForm"
          [apiRoute]="apiRoute"
          toastMessage="Mensaje enviado con éxito"
          errorMessage="Error al crear el mensaje, inténtalo de nuevo"
          [insideModal]="true"
          [insideModalTitle]="title"
          [disabledClauses]="emailCandidates.length === 0"
          [saveButtonText]="'Enviar'"
          (closeModalContainer)="closeDialog()"
          (preSubmitFunction)="onPreSubmit()"
          (postSubmitFunction)="onPostSubmit($event)">

  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center" class="message-candidates">
    <div *ngIf="emailCandidates.length > 1" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="start center">
      <span>{{ emailCandidates.length }} Destinatarios:</span>

      <div *ngIf="!showCandidates" class="show-candidates-container primary-color"
           fxLayout="row" fxLayoutAlign="start center" (click)="onShowCandidates()">
        <span>Mostrar</span>
        <mat-icon [svgIcon]="'chevron-down'"></mat-icon>
      </div>
      <div *ngIf="showCandidates" class="show-candidates-container primary-color"
           fxLayout="row" fxLayoutAlign="start center" (click)="onShowCandidates()">
        <span>Ocultar</span>
        <mat-icon [svgIcon]="'chevron-up'"></mat-icon>
      </div>
    </div>
    <span *ngIf="emailCandidates.length == 1">Destinatario:</span>
    <mat-chip-listbox>
      <ng-container *ngIf="nameCandidates.length >= 1 && showCandidates || nameCandidates.length == 1">
        <mat-chip class="email-chip" selected="true" *ngFor="let candidate of nameCandidates; index as i"
                  [removable]="nameCandidates.length > 1" (removed)="onRemoveNameCandidate(candidate, i)">
          {{ candidate }}
          <mat-icon svgIcon="close" matChipRemove class="primary-color" *ngIf="nameCandidates.length > 1"></mat-icon>
        </mat-chip>
      </ng-container>

      <ng-container *ngIf="!hideEmails && (emailCandidates.length == 1 || showCandidates)">
        <mat-chip class="email-chip" selected="true" *ngFor="let candidate of emailCandidates"
                  [removable]="emailCandidates.length > 1" (removed)="onRemoveCandidate(candidate)">
          {{ candidate }}
          <mat-icon svgIcon="close" matChipRemove class="primary-color" *ngIf="emailCandidates.length > 1"></mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-listbox>
  </div>

  <bracelit-form-item fxFlex="100" [id]="'subject'" [label]="'Asunto'"
                 [placeholder]="'Escribe aquí tu mensaje'"
                 [textAreaMinRows]="12"
                 [bracelitFormControl]="myForm.controls['subject']"></bracelit-form-item>

  <bracelit-form-item fxFlex="100" [type]="'textarea'" [id]="'content'" [label]="'Contenido'"
                 [placeholder]="'Escribe aquí tu mensaje'"
                 [textAreaMinRows]="12"
                 [bracelitFormControl]="myForm.controls['content']"></bracelit-form-item>

  <bracelit-file-uploader [allowedFileFormats]="['*']"
                     [bracelitFormControl]="myForm.controls['documents']"
                     [category]="'document'"
                     [title]="''"
                     [inputText]="'AGREGAR DOCUMENTO'"
                     [dropBoxText]="'Arrastrar documento aquí'">
  </bracelit-file-uploader>

  <div class="files-tip" fxFlex="100">
    {{ filesTip }}
  </div>

  <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
    <bracelit-form-item fxFlex="50" fxFlex.lt-md="100" [type]="'checkbox'" [id]="'content'"
                   [label]="'Enviar también por email'"
                   [helpTooltip]="isEmailTooltip"
                   [bracelitFormControl]="myForm.controls['isEmail']"></bracelit-form-item>

    <div *ngIf="emailCandidates.length === 0" fxFlex="50" fxFlex.lt-md="100"
         class="warn-color no-candidates-tip" fxLayoutAlign="end center" fxLayout="row">
      <mat-icon svgIcon="alert-circle-outline"></mat-icon>
      <span>{{ noCandidatesTip }}</span>
    </div>
  </div>
</bracelit-form>
