import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ActivatedRoute, Router } from '@angular/router';
import { delay } from 'rxjs/operators';

import { ApiService } from '@shared/services';

import { BracelitConfirmDeleteComponent } from '@app/shared/bracelit-components/common/bracelit-confirm-delete';

import { submitStateTrigger } from './animations';

/**
 * BracelitFormComponent, wrapper for the forms of the app.
 * Supports i18n.
 */
@Component({
    selector: 'bracelit-form',
    templateUrl: './bracelit-form.component.html',
    styleUrls: ['./bracelit-form.component.scss'],
    animations: [submitStateTrigger],
    standalone: false
})
export class BracelitFormComponent implements OnInit, OnChanges {
  @Input() bracelitFormGroup: FormGroup;
  @Input() id: string = 'form-id-' + Math.floor((Math.random() * 1000) + 1);
  @Input() layoutGap = '0';
  @Input() editEnabled = true;
  @Input() triggerSubmit = false;
  @Input() forceSubmit = false;
  @Input() showButtons = true;
  @Input() customSubmit = false; // if false it acts as a normal form
  @Input() customDelete = false; // if false it acts as a normal form
  @Input() buttonIconsEnabled = false;
  @Input() submitAnimationEnabled = false;
  /* Delete inputs */
  @Input() deleteReasonEnabled = false;
  @Input() deleteEnabled = true;
  @Input() deleteButtonIcon = 'delete';
  @Input() deleteButtonText = (localStorage.getItem('lang') === 'es') ? 'Eliminar' : 'Delete';
  @Input() deleteToastMessage = 'Modelo eliminado con éxito';
  @Input() deleteModalObjectName = 'al elemento';
  @Input() deleteReasonLabel = 'Motivo de la baja';
  @Input() deleteWarningText = '¡Cuidado!';
  @Input() deleteWarningDescription = 'Si confirmas esta acción se borrarán permanentemente todos los datos relativos';

  @Input() primaryRaisedButton = true;
  @Input() fullHeight = false;
  @Input() insideModal = false;
  @Input() closeContainerEnabled = true;
  @Input() insideModalTitle = '';
  @Input() disabledClauses: any = null;
  @Input() backEnabled = true;
  @Input() closeText = (localStorage.getItem('lang') === 'es') ? 'Cerrar' : 'Close';
  @Input() backText = (localStorage.getItem('lang') === 'es') ? 'Volver' : 'Back';
  @Input() apiRoute = '';
  @Input() deleteApiRoute = '';
  @Input() method = 'post';
  @Input() saveButtonText = (localStorage.getItem('lang') === 'es') ? 'Guardar' : 'Save';
  @Input() editButtonText = (localStorage.getItem('lang') === 'es') ? 'Editar' : 'Edit';
  @Input() cancelButtonText: string;
  @Input() redirectBack = false;
  @Input() redirectToItem = false;
  @Input() redirectToItemAndEdit = false;
  @Input() redirectToItemAndPrint = false;
  @Input() redirectUrl = '';
  @Input() redirectUrlAfterDelete = '';
  @Input() preventEnterSubmit = true;
  @Input() toastMessage = '';
  @Input() errorMessage = '';
  @Input() formDisabled = false;
  @Input() editableForm = false;
  @Input() noSubmit = false;
  @Input() noDelete = false;
  @Input() title = '';
  @Input() centeredTitle = false;
  @Input() primaryColoredTitle = true;
  @Input() formWithoutPadding = false;
  @Input() requiredButtonSign = false;
  @Input() requiredSign = true;
  //@Input() requiredText = (localStorage.getItem('lang') === 'es') ? '* Campos obligatorios' : '* Required field';
  @Input() requiredText: string;
  @Input() extraMarginBottom = 0;
  @Input() submitButtonColor: ThemePalette = 'primary';
  @Input() deleteButtonColor: ThemePalette = 'warn';
  @Input() cancelButtonColor: ThemePalette;
  @Input() submitButtonClasses: string;

  @Output() readonly preSubmitFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly postSubmitFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly postDeleteFunction: EventEmitter<any> = new EventEmitter();
  @Output() readonly closeModalContainer: EventEmitter<any> = new EventEmitter();
  @Output() readonly cancelForm: EventEmitter<any> = new EventEmitter();
  @Output() readonly responseOutput: EventEmitter<any> = new EventEmitter();

  deleteFormGroup: FormGroup;
  submitting = false;
  error: Error = null;
  hasRequiredFields = false;

  /**
   * Constructor.
   * @param router
   * @param apiService
   * @param formBuilder
   * @param route
   * @param location
   * @param matSnackBar
   * @param matDialog
   */
  constructor(private router: Router,
              private apiService: ApiService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private location: Location,
              private matSnackBar: MatSnackBar,
              private matDialog: MatDialog) {
    this.deleteFormGroup = this.formBuilder.group({
      deleteReason: [''],
    });
  }

  private static bracelitMarkAsDirty(control) {
    control.markAsTouched();
    control.markAsDirty();
  }

  ngOnInit() {
    if (!this.deleteApiRoute) {
      this.deleteApiRoute = this.apiRoute;
    }
    if (this.formDisabled) {
      this.bracelitFormGroup.disable();
    }
    if (this.requiredSign) {
      if (this.checkIfRequired(this.bracelitFormGroup)) {
        this.hasRequiredFields = true;
      }
    }
  }

  ngOnChanges($event: SimpleChanges) {
    if ($event['triggerSubmit']) {
      if (!$event['triggerSubmit'].firstChange) {
        this.onSubmit();
      }
    }
  }

  openConfirmDelete(): void {
    if (!this.customDelete) {
      const dialogRef = this.matDialog.open(BracelitConfirmDeleteComponent, {
        width: '500px',
        minWidth: '320px',
        data: {
          deleteModalObjectName: this.deleteModalObjectName,
          backText: this.backText,
          closeText: this.closeText,
          deleteButtonText: this.deleteButtonText,
          deleteReasonEnabled: this.deleteReasonEnabled,

          deleteWarningText: this.deleteWarningText,
          deleteWarningDescription: this.deleteWarningDescription,
          deleteReasonLabel: this.deleteReasonLabel,
          deleteToastMessage: this.deleteToastMessage,
        }
      });

      const subDelete = dialogRef.componentInstance.postDeleteEmitter.subscribe((deleteReason) => {
        if (typeof deleteReason !== 'undefined') {
          if (this.deleteReasonEnabled) {
            this.deleteFormGroup.controls['deleteReason'].setValue(deleteReason);
          }
        } else {
          this.deleteFormGroup.controls['deleteReason'].setValue('');
        }
        this.onDelete();
      });

      dialogRef.afterClosed().pipe(delay(3000)).subscribe(() => {
        subDelete.unsubscribe();
      });
    } else {
      this.postDeleteFunction.emit();
    }
  }

  onSubmit() {
    if (!this.customSubmit) {
      if (!this.bracelitFormGroup.valid && !this.forceSubmit) {
        this.markFormGroupDirty(this.bracelitFormGroup);
      } else {
        if (!this.submitting) {
          this.preSubmitFunction.emit();
          this.submitting = true;
          if (this.noSubmit) {
            this.submitting = false;
            this.postSubmitFunction.emit(this.bracelitFormGroup.value);
          } else {
            this.apiService[this.method](this.apiRoute, this.bracelitFormGroup.value).subscribe(
              response => {
                let id = null;
                if (response && response.id) {
                  id = response.id;
                  if (!this.bracelitFormGroup.contains('id')) {
                    const control = new FormControl(id);
                    this.bracelitFormGroup.addControl('id', control);
                  } else {
                    this.bracelitFormGroup.controls['id'].setValue(id);
                  }
                }
                this.postSubmitFunction.emit(this.bracelitFormGroup.value);
                this.responseOutput.emit(response);
                if (this.toastMessage !== '') {
                  this.openSnackBar(this.toastMessage);
                }
                if (this.method === 'patch' && this.editableForm) {
                  this.bracelitFormGroup.disable();
                  this.formDisabled = true;
                } else {
                  if (this.redirectUrl) {
                    if (!this.redirectToItem && !this.redirectToItemAndPrint && !this.redirectToItemAndEdit) {
                      this.router.navigate([this.redirectUrl], {relativeTo: this.route});
                    } else {
                      if (id) {
                        if (this.redirectToItem) {
                          this.router.navigate([this.redirectUrl + '/' + id], {relativeTo: this.route});
                        } else if (this.redirectToItemAndPrint) {
                          this.router.navigate([this.redirectUrl + '/' + id, {print: true}, {relativeTo: this.route}]);
                        } else if (this.redirectToItemAndEdit) {
                          this.router.navigate([this.redirectUrl + '/' + id + '/editar'], {relativeTo: this.route});
                        }
                      } else {
                        this.router.navigate([this.redirectUrl], {relativeTo: this.route});
                      }
                    }
                  }
                }
                this.submitting = false;
              },
              error => {
                this.error = <any>error;
                this.submitting = false;
              });
          }
        }
      }
    } else {
      this.submitting = true;
      if (!this.bracelitFormGroup.valid && !this.forceSubmit) {
        this.markFormGroupDirty(this.bracelitFormGroup);
      }
      this.responseOutput.next(this.bracelitFormGroup);
      this.submitting = false;
    }
  }

  onEdit() {
    if (this.editableForm) {
      this.formDisabled = false;
      this.bracelitFormGroup.enable();
    }
  }

  onBack() {
    if (this.redirectBack) {
      this.location.back();
      window.scroll(0, 0);
    } else {
      this.router.navigate([this.redirectUrl], {relativeTo: this.route});
    }
  }

  onDelete() {
    if (this.noDelete) {
      this.postDeleteFunction.emit();
    } else {
      if (!this.submitting) {
        this.submitting = true;
        this.apiService.delete(this.deleteApiRoute, this.deleteFormGroup.value).subscribe(
          () => {
            this.postDeleteFunction.emit();
            this.submitting = false;
            this.openSnackBar(this.deleteToastMessage);
            if (this.redirectUrl && !this.redirectUrlAfterDelete) {
              this.router.navigate([this.redirectUrl], {relativeTo: this.route});
            } else if (this.redirectUrlAfterDelete) {
              this.router.navigate([this.redirectUrlAfterDelete], {relativeTo: this.route});
            }
          },
          error => {
            this.error = error as any;
            this.submitting = false;
          });
      }
    }
  }

  onClose() {
    this.closeModalContainer.emit(true);
  }

  onCancel() {
    this.cancelForm.emit(true);
  }

  checkPreventDefault($e: any) {
    if (this.preventEnterSubmit) {
      const tagName = $e.target.tagName.toLowerCase();
      if (tagName !== 'textarea') {
        return false;
      }
    }
  }

  /**
   * Opens a snackbar.
   * @param message
   * @param action
   */
  openSnackBar(message: string, action: string = this.closeText) {
    this.matSnackBar.open(message, action, {
      duration: 6000,
    });
  }

  /**
   * Marks the form as dirty.
   * @param formGroup
   */
  private markFormGroupDirty(formGroup: FormGroup) {
    if (formGroup.controls) {
      (Object as any).values(formGroup.controls).forEach(control => {
        if (control instanceof FormGroup) {
          this.markFormGroupDirty(control);
        } else {
          (control.controls) ? (Object as any).values(control.controls).forEach(
            c => this.markFormGroupDirty(c)) : BracelitFormComponent.bracelitMarkAsDirty(control);
        }
      });
    }
  }

  /**
   * Checks if the abstractControl is required.
   * @param abstractControl
   */
  private checkIfRequired(abstractControl: AbstractControl) {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl['controls']) {
      for (const controlName in abstractControl['controls']) {
        if (abstractControl['controls'][controlName]) {
          if (this.checkIfRequired(abstractControl['controls'][controlName])) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
