import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { Bracelit404Module } from '@app/shared/bracelit-components/bracelit-404';
import { BracelitAlertModule } from '@app/shared/bracelit-components/bracelit-alert';
import { BracelitBreadcrumbModule } from '@app/shared/bracelit-components/bracelit-breadcrumb';
import { BracelitEmailModule } from '@app/shared/bracelit-components/bracelit-email';
import { BracelitToolbarModule } from '@app/shared/bracelit-components/bracelit-toolbar';
import { BracelitWizardFormModule } from '@app/shared/bracelit-components/bracelit-wizard-form';
import { BracelitWizardHelpModule } from '@app/shared/bracelit-components/bracelit-wizard-help';
import { BracelitConfirmDeleteModule } from '@app/shared/bracelit-components/common/bracelit-confirm-delete';
import { BracelitConfirmNavigationModule } from '@app/shared/bracelit-components/common/bracelit-confirm-navigation';
import { BracelitDirectivesModule } from '@bracelit-components/common/bracelit-directives/bracelit-directives.module';
import { BracelitFileUploaderModule } from '@app/shared/bracelit-components/common/bracelit-file-uploader';
import { BracelitImagePreviewModule } from '@app/shared/bracelit-components/common/bracelit-image-preview';
import { BracelitPipesModule } from '@bracelit-components/common/bracelit-pipes';
import { BracelitPreloaderModule } from '@app/shared/bracelit-components/common/bracelit-preloader';
import { MaterialModule } from './angular-material';
import { ActionsTopBarComponent } from './components/actions-top-bar/actions-top-bar.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { InvitationsDialogComponent } from './components/invitations-dialog/invitations-dialog.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ApiService, SnackBarService } from './services';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    LoaderComponent,
    ActionsTopBarComponent,
    ConfirmationDialogComponent,
    InvitationsDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    Bracelit404Module,
    BracelitAlertModule,
    BracelitBreadcrumbModule,
    BracelitEmailModule,
    BracelitToolbarModule,
    BracelitWizardFormModule,
    BracelitWizardHelpModule,
    BracelitConfirmDeleteModule,
    BracelitConfirmNavigationModule,
    BracelitFileUploaderModule,
    BracelitImagePreviewModule,
    BracelitPreloaderModule,
    BracelitPipesModule,
    BracelitDirectivesModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule,
    Bracelit404Module,
    BracelitAlertModule,
    BracelitBreadcrumbModule,
    BracelitEmailModule,
    BracelitToolbarModule,
    BracelitWizardFormModule,
    BracelitWizardHelpModule,
    BracelitConfirmDeleteModule,
    BracelitConfirmNavigationModule,
    BracelitFileUploaderModule,
    BracelitImagePreviewModule,
    BracelitPreloaderModule,
    BracelitPipesModule,
    BracelitDirectivesModule,
    FooterComponent,
    ActionsTopBarComponent
  ],
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ApiService,
        DatePipe,
        SnackBarService
      ]
    };
  }
}
