import { Component } from '@angular/core';

import { notFoundConfiguration } from '../bracelit-components.configuration';

/**
 * Bracelit404Component, error page when 404.
 */
@Component({
    selector: 'bracelit-404',
    templateUrl: './bracelit-404.component.html',
    styleUrls: ['./bracelit-404.component.scss'],
    standalone: false
})
export class Bracelit404Component {
  /**
   * notFoundImage, illustration recommended. (default icon)
   * @type {string}
   */
  notFoundImageUrl: string = notFoundConfiguration.notFoundImageUrl;
  /**
   * notFoundText. (default "Ha ocurrido algún error")
   * @type {string}
   */
  notFoundText: string = notFoundConfiguration.notFoundText;
  /**
   * notFoundAdditionalText. (default null)
   * @type {string}
   */
  notFoundAdditionalText: string = notFoundConfiguration.notFoundAdditionalText;
}
