import { Component, Input, OnInit } from '@angular/core';
import esJson from '../../../../assets/i18n/es.json';
import enJson from '../../../../assets/i18n/en.json';
import { LoaderService } from '@app/shared/services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: false
})
export class LoaderComponent implements OnInit {
  @Input() activeTab: string;
  components: any[] = [];
  options: string[] = [];
  nButtons: number;
  icons: any[] = [];
  actives: any[] = [];
  class: any[] = [];
  public flag = false;
  public hasVouchers: boolean;
  public hasAccessControl: boolean;
  public isTicketScan: boolean;
  public hasPurchases: boolean;
  public hasOnlineOrders: boolean;
  public isTravel: boolean;
  roleUser: any;
  userId: any;
  eventSlug: any;
  langJson: any;

  constructor(public loaderService: LoaderService) {
    this.langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  }

  ngOnInit(): void {


  }


  }

