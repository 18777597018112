import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * BracelitCurrency, pipe to transform a number to euros.
 */
@Pipe({
    name: 'bracelitCurrency',
    standalone: false
})
export class BracelitCurrencyPipe implements PipeTransform {

  /**
   * Pipe transform.
   * @param value
   * @param args
   */
  transform(value: any, args?: any): any {
    return new CurrencyPipe('es').transform(value, 'EUR', 'symbol');
  }
}
