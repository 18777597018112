import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

/**
 * BracelitBackgroundImage directive, sets a background image in the container.
 */
@Directive({
    selector: '[bracelitBackgroundImage]',
    standalone: false
})
export class BracelitBackgroundImageDirective implements AfterViewInit, OnChanges {
  /**
   * The backgroundImage url.
   * @type {string}
   */
  @Input('bracelitBackgroundImage') backgroundImage: string;
  /**
   * element reference.
   * @type {HTMLElement}
   */
  private readonly el: HTMLElement;

  /**
   * Constructor.
   * @param renderer
   * @param el
   */
  constructor(private renderer: Renderer2, el: ElementRef) {
    this.el = el.nativeElement;
  }

  /**
   * AfterViewInit lifecycle.
   */
  ngAfterViewInit() {
    this.setBackgroundImage();
  }

  /**
   * OnChanges lifecycle.
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['backgroundImage']) {
      this.setBackgroundImage();
    }
  }

  /**
   * Sets the background image.
   */
  setBackgroundImage() {
    if (this.backgroundImage) {
      this.renderer.setStyle(this.el, 'backgroundImage', `url(${this.backgroundImage})`);
    }
  }
}
