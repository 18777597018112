import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

/**
 * BracelitBackgroundImage directive, sets a background image in the container.
 */
@Directive({
    selector: '[bracelitCanvasDrawDirective]',
    standalone: false
})
export class BracelitCanvasDrawDirective implements OnInit, AfterViewInit, OnChanges {
  @Input() imageUrl = '=';
  @Input() enabled = false;
  @Input() editCenters = false;
  @Input() palette: string[] = [];
  @Input() points: number[][][] = [];
  @Input() centers: number[][] = [];
  @Input() centersColors: string[] = []; // example '#000000'
  @Input() centersBorderColor = '#808080';
  @Input() centersBorderWidth = 2;
  @Input() centerTransparency = 1;
  @Input() centerRadius = 5;
  @Input() activePolygon = 0;
  @Input() activatePolygons = true;
  @Input() forceRefresh: boolean;
  @Input() refreshImage: boolean;
  @Input() hoverActive = false;
  @Input() maxHeight: number;
  @Input() maxWidth: number;
  @Input() transparency = 0.3;
  @Input() lineWidth = 1;

  @Output() readonly pointsOutput = new EventEmitter();
  @Output() readonly centersOutput = new EventEmitter();
  @Output() readonly polygonHoverIndex = new EventEmitter();
  @Output() readonly imageHeight: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly imageWidth: EventEmitter<number> = new EventEmitter<number>();

  private oldActiveValue: number;
  private activePoint = 0;
  private $canvas: any;
  private ctx: CanvasRenderingContext2D;
  private mouseMoveListenerBind;

  constructor(
    private elementRef: ElementRef,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.oldActiveValue = this.activePolygon;
    this.elementRef.nativeElement.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.elementRef.nativeElement.addEventListener('mouseup', this.stopdrag.bind(this));
    this.elementRef.nativeElement.addEventListener('contextmenu', this.onRightClick.bind(this));
    if (this.hoverActive) {
      this.elementRef.nativeElement.addEventListener('mousemove', this.showHovered.bind(this));
    }
    this.mouseMoveListenerBind = this.move.bind(this);
  }

  ngAfterViewInit(): void {
    this.link(this, this.elementRef.nativeElement);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.forceRefresh) {
      if (this.$canvas && this.points) {
        this.draw();
      }
      // this.record();
    }
    if (changes.refreshImage || changes.maxWidth || changes.maxHeight) {
      this.imageWidth.next(0);
      this.imageHeight.next(0);
      const image = new Image();
      image.src = this.imageUrl;
      image.addEventListener('load', ($event) => {
        this.resizeImage(image);
        this.$canvas.width = image.width;
        this.$canvas.height = image.height;
        this.$canvas.style.width = `'${image.width}px'`;
        this.$canvas.style.height = `'${image.height}px'`;
        this.$canvas.style.background = 'url(' + image.src + ')';
        this.$canvas.style.backgroundSize = '100% auto';
        this.$canvas.style.backgroundRepeat = 'no-repeat';
        this.$canvas.style.backgroundPosition = 'center';
        this.imageWidth.next(image.width);
        this.imageHeight.next(image.height);
        if (this.$canvas && this.points) {
          this.draw();
        }
        // this.record();
      });
    }
    if (changes.points) {
      if (this.$canvas && this.points) {
        this.draw();
      }
      this.pointsOutput.emit(this.points);
      // this.changedetectorRef.detectChanges();
    }
    if (changes.center) {
      if (this.$canvas && this.centers && this.centers.length > 0) {
        // TODO: check if this.draw needed
        this.drawCenters();
      }
      this.centersOutput.emit(this.centers);
    }
    if (changes.active) {
      if (this.activePolygon !== this.oldActiveValue) {
        if (this.$canvas && this.points) {
          this.draw();
          this.oldActiveValue = this.activePolygon;
        }
      }
    }
    if (changes.activePolygon) {
      if (this.$canvas && this.points) {
        this.draw();
      }
    }
  }

  dotLineLength(x, y, x0, y0, x1, y1, o) {
    if (o &&
      !(
        // tslint:disable-next-line:no-conditional-assignment
        o = this.oAlternativeCalc(x, y, x0, y0, x1, y1), o.x >= Math.min(x0, x1)
        && o.x <= Math.max(x0, x1)
        && o.y >= Math.min(y0, y1)
        && o.y <= Math.max(y0, y1)
      )

    ) {
      const l1 = this.lineLength(x, y, x0, y0);
      const l2 = this.lineLength(x, y, x1, y1);

      return l1 > l2 ? l2 : l1;
    } else {
      const a = y0 - y1;
      const b = x1 - x0;
      const c = x0 * y1 - y0 * x1;

      return Math.abs(a * x + b * y + c) / Math.sqrt(a * a + b * b);
    }
  }

  oAlternativeCalc(x, y, x0, y0, x1, y1) {
    if (!(x1 - x0)) {
      return {x: x0, y: y};
    } else if (!(y1 - y0)) {
      return {x: x, y: y0};
    }
    let left;
    const tg = -1 / ((y1 - y0) / (x1 - x0));

    return {
      x: left = (x1 * (x * tg - y + y0) + x0 * (x * -tg + y - y1)) / (tg * (x1 - x0) + y0 - y1),
      y: tg * left - tg * x + y
    };
  }

  lineLength(x, y, x0, y0) {
    return Math.sqrt((x -= x0) * x + (y -= y0) * y);
  }

  hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  link(scope, element) {
    this.activePoint = null;
    const settings = {imageUrl: ''};
    this.$canvas = document.createElement('canvas');
    this.$canvas.id = 'CursorLayer';
    // this.$canvas.style.width = String(this.elementRef.nativeElement.offsetWidth + 'px');
    // this.$canvas.style.width = String(380 + 'px');
    // this.$canvas.style.width = String(1224 + 'px');
    // this.$canvas.style.height = String(380 + 'px');
    // this.$canvas.style.height = String(this.elementRef.nativeElement.offsetHeight + 'px');
    // this.$canvas.style.height = String(768 + 'px');
    this.$canvas.style.zIndex = String(8);
    this.$canvas.style.position = 'absolute';
    // this.$canvas.style.border = '1px solid';
    let image;
    settings.imageUrl = this.imageUrl;

    if (!this.points) {
      this.points = [[]];
    }

    if (!this.activePolygon) {
      this.activePolygon = 0;
    }

    const body = document.getElementsByTagName('body')[0];
    body.appendChild(this.$canvas);
    // const cursorLayer = document.getElementById('CursorLayer');
    this.ctx = this.$canvas.getContext('2d');
    image = new Image();
    // $(image).load(scope.resize);
    // image.nativeElement.onload;
    // image.nativeElement.onload(() => {
    //   this.scopeResize(scope);
    // });
    // image.nativeElement.addEventListener('onload', this.scopeResize(this) {
    //   // ...
    // });
    image.src = settings.imageUrl;
    image.addEventListener('load', () => {
      this.resizeImage(image);
      this.$canvas.width = image.width;
      this.$canvas.height = image.height;
      this.$canvas.style.width = `'${image.width}px'`;
      this.$canvas.style.height = `'${image.height}px'`;
      this.$canvas.style.background = 'url(' + image.src + ')';
      this.$canvas.style.backgroundSize = '100% auto';
      this.$canvas.style.backgroundRepeat = 'no-repeat';
      this.$canvas.style.backgroundPosition = 'center';
      this.imageWidth.next(image.width);
      this.imageHeight.next(image.height);
      element.append(this.$canvas);
      this.changeDetector.detectChanges();
      if (this.points.length > 0) {
        // Draw initial points
        this.draw();
      }
    });
  }

  /**
   * Adapt image size if maxWidth and/or maxWidth have been set.
   * @param image
   */
  resizeImage(image: HTMLImageElement): HTMLImageElement {
    const originalWidth = image.width;
    const originalHeight = image.height;

    if (this.maxHeight && this.maxWidth) {
      const xDiff = this.maxWidth - image.width;
      const yDiff = this.maxHeight - image.height;
      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // Adjust X and then Y proportionally
        if (image.width > this.maxWidth || image.width < this.maxWidth ) {
          image.width = this.maxWidth;
          // Adapting height proportionally
          image.height = (originalHeight / originalWidth) * image.width;
        }
      } else {
        // Adjust Y and then X proportionally
        if (image.height > this.maxHeight || image.height < this.maxHeight) {
          image.height = this.maxHeight;
          // Adapting width proportionally
          image.width = (originalWidth / originalHeight) * image.height;
        }
      }
    } else {
      if (this.maxHeight && image.height > this.maxHeight) {
        // if only maxHeight
        image.height = this.maxHeight;
        // Adapting width proportionallyº
        image.width = (originalWidth / originalHeight) * image.height;
      }

      if (this.maxWidth && image.width > this.maxWidth) {
        // if only maxWidth
        image.width = this.maxWidth;
        // Adapting height proportionally
        image.height = (originalHeight / originalWidth) * image.width;
      }
    }

    return image;
  }

  onRightClick(e) {
    if (e) {
      e.preventDefault();
      if (!e.offsetX) {
        e.offsetX = (e.pageX - e.target.offset().left);
        // e.offsetX = (e.pageX - $(e.target).offset().left);
        e.offsetY = (e.pageY - e.target.offset().top);
        // e.offsetY = (e.pageY - $(e.target).offset().top);
      }
      const x = e.offsetX;
      const y = e.offsetY;
      const points = this.points[this.activePolygon];
      for (let i = 0; i < points.length; ++i) {
        const dis = Math.sqrt(Math.pow(x - points[i][0], 2) + Math.pow(y - points[i][1], 2));
        if (dis < 6) {
          points.splice(i, 1);
          this.draw();
          this.record();

          return false;
        }
      }
    }

    return false;
  }

  onMouseDown($event) {
    if (!this.enabled) {
      return false;
    }
    const points = this.points[this.activePolygon];
    let x;
    let y;
    let dis;
    let minDis: number;
    let minDisIndex: number;
    let lineDis;
    let insertAt: any;
    minDis = 0;
    minDisIndex = -1;
    insertAt = points.length;

    if ($event.which === 3) {
      return false;
    }
    $event.preventDefault();
    if (!$event.offsetX) {
      $event.offsetX = ($event.pageX - $event.target.offset().left);
      // e.offsetX = (e.pageX - $(e.target).offset().left);
      $event.offsetY = ($event.pageY - $event.target.offset().top);
      // e.offsetY = (e.pageY - $(e.target).offset().top);
    }
    const mousePos = this.getMousePos($event);
    x = mousePos.x;
    y = mousePos.y;

    // Editing points
    if (!this.editCenters) {
      for (let i = 0; i < points.length; ++i) {
        dis = Math.sqrt(Math.pow(x - points[i][0], 2) + Math.pow(y - points[i][1], 2));
        if (minDisIndex === -1 || minDis > dis) {
          minDis = dis;
          minDisIndex = i;
        }
      }
      if (minDis < 6 && minDisIndex >= 0) {
        this.activePoint = minDisIndex;
        this.elementRef.nativeElement.addEventListener('mousemove', this.mouseMoveListenerBind);

        return false;
      }

      for (let i = 0; i < points.length; ++i) {
        if (i > 1) {
          lineDis = this.dotLineLength(
            x, y,
            points[i][0], points[i][1],
            points[i - 1][0], points[i - 1][1],
            true
          );
          if (lineDis < 6) {
            insertAt = i;
          }
        }
      }
      points.splice(insertAt, 0, [Math.round(x), Math.round(y)]);
      this.activePoint = insertAt;
      this.elementRef.nativeElement.addEventListener('mousemove', this.mouseMoveListenerBind);

    } else {
      // Editing centers
      const center = this.centers[this.activePolygon];
      dis = Math.sqrt(Math.pow(x - center[0], 2) + Math.pow(y - center[1], 2));
      if (minDisIndex === -1 || minDis > dis) {
        minDis = dis;
      }

      if (minDis < 6) {
        // this.activePoint = 0;
        this.elementRef.nativeElement.addEventListener('mousemove', this.mouseMoveListenerBind);

        return false;
      }
      this.centers.splice(this.activePolygon, 1, [Math.round(x), Math.round(y)]);
      this.elementRef.nativeElement.addEventListener('mousemove', this.mouseMoveListenerBind);
    }

    this.draw();
    this.record();

    return false;
  }

  getMousePos(evt) {
    const rect = this.$canvas.getBoundingClientRect();

    return {
      x: evt.clientX - rect.left,
      y: evt.clientY - rect.top
      // x: evt.clientX,
      // y: evt.clientY
    };
  }

  move(e) {
    if (!e.offsetX && e.target.offset()) {
      e.offsetX = (e.pageX - e.target.offset().left);
      e.offsetY = (e.pageY - e.target.offset().top);
    }
    const points = this.points[this.activePolygon];
    if (!this.editCenters) {
      points[this.activePoint][0] = Math.round(e.offsetX);
      points[this.activePoint][1] = Math.round(e.offsetY);
    } else {
      this.centers[this.activePolygon][0] = Math.round(e.offsetX);
      this.centers[this.activePolygon][1] = Math.round(e.offsetY);
    }

    this.record();
    this.draw();
  }

  stopdrag() {
    this.elementRef.nativeElement.removeEventListener('mousemove', this.mouseMoveListenerBind);
    this.record();
    this.activePoint = null;
  }

  draw() {
    this.$canvas.width = this.$canvas.width; // DO NOT REMOVE, MOOVING BREAKS IF REMOVED
    if (this.points.length > 0) {
      this.drawSingle(this.points[this.activePolygon], this.activePolygon);
    }
    for (let p = 0; p < this.points.length; ++p) {
      const points = this.points[p];
      if (points.length === 0 || this.activePolygon === p) {
        continue;
      }
      if (points && p) {
        this.drawSingle(points, p);
      }
    }
    if (this.centers && this.centers.length > 0) {
      this.drawCenters();
    }
  }

  drawCenters() {
    // border of the center #808080
    let centerIndex = 0;
    for (const center of this.centers) {
      let fillColor = this.hexToRgb('#000000');
      if (this.centersColors[centerIndex]) {
        fillColor = this.hexToRgb(this.centersColors[centerIndex]);
      }
      this.ctx.fillStyle = 'rgba(' + fillColor.r + ',' + fillColor.g + ',' + fillColor.b + ', ' + this.centerTransparency + ')';
      this.ctx.beginPath();
      this.ctx.arc(center[0], center[1], this.centerRadius, 0, 2 * Math.PI);
      this.ctx.fill();

      if (this.centersBorderWidth > 0) {
        // center border
        const borderFillColor = this.hexToRgb(this.centersBorderColor);
        this.ctx.fillStyle = 'rgba(' + borderFillColor.r + ',' + borderFillColor.g + ',' + borderFillColor.b + ', ' + this.centerTransparency + ')';
        this.ctx.beginPath();
        this.ctx.arc(center[0], center[1], this.centerRadius + this.centersBorderWidth, 0, 2 * Math.PI);
        this.ctx.fill();
      }
      centerIndex++;
    }
    this.centersOutput.emit(this.centers);
  }

  drawSingle(points, p) {
    this.ctx.globalCompositeOperation = 'destination-over';
    this.ctx.fillStyle = `rgba(255,255,255, ${this.transparency})`;
    this.ctx.strokeStyle = this.palette[p];
    this.ctx.lineWidth = this.lineWidth;

    this.ctx.beginPath();
    // ctx.moveTo(points[0], points[1]);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < points.length; ++i) {
      // activatePolygons activates or deactivates the white squares on the active polygon
      if (this.activePolygon === p && this.activatePolygons) {
        this.ctx.fillRect(points[i][0] - 2, points[i][1] - 2, 4, 4);
        this.ctx.strokeRect(points[i][0] - 2, points[i][1] - 2, 4, 4);
      }
      if (this.lineWidth > 0) {
        this.ctx.lineTo(points[i][0], points[i][1]);
      }
    }
    this.ctx.closePath();

    if (!this.palette[p]) {
      this.palette[p] = '#' + (function lol(m, s, c) {
        return s[m.floor(m.random() * s.length)] + (c && lol(m, s, c - 1));
      })(Math, '0123456789ABCDEF', 4);
    }

    const hexPattern = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');
    const rgbaPattern = new RegExp(/rgba\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*.\d+)\s*\)/);

    let fillColor;
    if (this.palette[p] && hexPattern.exec(this.palette[p])) {
      // if this.palette[p] is in hex format, transparency set to 0.3
      fillColor = this.hexToRgb(this.palette[p]);
      this.transparency = 0.3;
    } else if (this.palette[p] && rgbaPattern.exec(this.palette[p])) {
      //  if this.palette[p] is in rgba format, transparency taken from alfa channel
      const result = rgbaPattern.exec(this.palette[p]);
      fillColor = {
        r: parseInt(result[1], 16),
        g: parseInt(result[3], 16),
        b: parseInt(result[5], 16)
      };
      this.transparency = parseFloat(result[7]);
    }

    // Color de relleno de los polígonos
    this.ctx.fillStyle = 'rgba(' + fillColor.r + ',' + fillColor.g + ',' + fillColor.b + ', ' + this.transparency + ')';
    this.ctx.fill();
    this.ctx.stroke();
  }

  resize(image: HTMLImageElement) {
    if (this.$canvas) {
      // this.$canvas.style.height = image.height;
      // this.$canvas.style.width = image.width;
      // this.$canvas.style.height = String(this.$canvas.offsetHeight);
      // this.$canvas.style.width = String(this.$canvas.offsetWidth);
      // this.draw();
    }
  }

  record() {
    this.pointsOutput.emit(this.points);
    // this.$apply();
    // TODO: detect changes?
    // this.changedetectorRef.detectChanges();
  }

  scopeResize(scope) {
    // $canvas.attr('height', image.height).attr('width', image.width);
    // $canvas.attr('height', $canvas[0].offsetHeight).attr('width', $canvas[0].offsetWidth);
    // this.$canvas.style.width = String(this.elementRef.nativeElement.offsetWidth + 'px');
    // // this.$canvas.style.width = String(1224 + 'px');
    // this.$canvas.style.height = String(this.elementRef.nativeElement.offsetHeight + 'px');
    // scope.draw();
  }

  /**
   * If a polygon gets hovered, emits its index, if any polygon is hovered emits null to refresh.
   * @param $event
   */
  showHovered($event) {
    let polygonIndex = 0;
    let polygonFound = false;
    for (const polygon of this.points) {
      if (this.inside(this.getMousePos($event), polygon)) {
        this.polygonHoverIndex.emit({
          polygonIndex: polygonIndex,
          polygonPoints: this.points[polygonIndex],
          mouseEvent: $event
        });
        polygonFound = true;
        break;
      }
      polygonIndex++;
    }
    if (!polygonFound) {
      this.polygonHoverIndex.emit(null);
    }
  }

  /**
   * Checks if a given mouse position ({x: 2, y: 3}) is inside a given polygon(array of poinst ([[1,2],[3,4],[5,6]])
   * @param point
   * @param vs
   */
  inside(point, vs) {
    // ray-casting algorithm based on
    // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

    // const x = point[0]; // original, if point is given as [1,2] like polygon corners.
    // const y = point[1]; // original, if point is given as [1,2] like polygon corners.
    const x = point.x;
    const y = point.y;
    let inside = false;
    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      const xi = vs[i][0];
      const yi = vs[i][1];
      const xj = vs[j][0];
      const yj = vs[j][1];

      const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  }
}
