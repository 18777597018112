import { Component, Inject } from '@angular/core';
//import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/**
 * BracelitImagePreview, used to display image/s in a modal. When multiple images, carousel mode is enabled.
 * Used in BracelitFileUploader.
 *
 * @example
 *
 * matDialog.open(BracelitImagePreviewComponent, {
 *    width: '800px',
 *    minWidth: '320px',
 *    data: {
 *      imagePreviews: this.imagePreviews,
 *      selectedImageIndex: i
 *    }
 * });
 */
@Component({
    selector: 'bracelit-image-preview',
    templateUrl: 'bracelit-image-preview.component.html',
    styleUrls: ['./bracelit-image-preview.component.scss'],
    standalone: false
})
export class BracelitImagePreviewComponent {
  /**
   * Title of the modal.
   * @type {string}
   */
  title = 'Visor de imágenes';
  /**
   * List of the image's url.
   * @type {string[]}
   */
  imagePreviews: string [] = [];
  /**
   * Initial selected image.
   * @type {number}
   */
  selectedImageIndex = 0;

  /**
   * Constructor.
   * @param dialogRef The dialog Reference.
   * @param data The dialog Data.
   */
  constructor(private dialogRef: MatDialogRef<BracelitImagePreviewComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imagePreviews = data.imagePreviews;
    if (this.imagePreviews.length > 0) {
      this.selectedImageIndex = data.selectedImageIndex;
    }
    if (data.title) {
      this.title = data.title;
    }
  }

  /**
   * Select the previous image of the carousel.
   */
  previousImage() {
    if (this.selectedImageIndex === 0) {
      this.selectedImageIndex = this.imagePreviews.length - 1;
    } else {
      this.selectedImageIndex--;
    }
  }

  /**
   * Select the next image of the carousel.
   */
  nextImage() {
    if (this.selectedImageIndex === this.imagePreviews.length - 1) {
      this.selectedImageIndex = 0;
    } else {
      this.selectedImageIndex++;
    }
  }

  /**
   * KeyDown event, used to manage left and right arrows.
   * @param $event
   */
  onKeyDown($event: KeyboardEvent) {
    if ($event.key === 'ArrowRight') {
      this.nextImage();
    } else if ($event.key === 'ArrowLeft') {
      this.previousImage();
    }
  }

  /**
   * Closes the dialog.
   */
  closeDialog(): void {
    this.dialogRef.close();
  }
}
