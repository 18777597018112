import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgxUploaderModule } from 'ngx-uploader';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { MaterialModule } from '@shared/angular-material';

import { BracelitDirectivesModule } from '@bracelit-components/common/bracelit-directives/bracelit-directives.module';
import { BracelitPreloaderModule } from '@app/shared/bracelit-components/common/bracelit-preloader';

import { BracelitCropperComponent } from './bracelit-cropper/bracelit-cropper.component';
import { BracelitFileUpLoaderComponent} from './bracelit-file-uploader.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    BracelitFileUpLoaderComponent,
    BracelitCropperComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxUploaderModule,
    ImageCropperComponent,
    BracelitPreloaderModule,
    BracelitDirectivesModule,
    FlexLayoutModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports: [
    BracelitFileUpLoaderComponent,
    BracelitCropperComponent,
    ImageCropperComponent,
  ]
})

export class BracelitFileUploaderModule {
}
