import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, Directive } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as FileSaver from 'file-saver';
import { humanizeBytes, UploaderOptions, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
//import { NgUploaderOptions, UploadedFile} from 'ngx-uploader';
import { delay, distinctUntilChanged } from 'rxjs/operators';

import { SavitarFile } from '@shared/models/savitar-file';
import { ApiService, AuthenticationService } from '@shared/services';

import { BracelitConfirmDeleteComponent } from '@app/shared/bracelit-components/common/bracelit-confirm-delete';
import { BracelitImagePreviewComponent } from '@app/shared/bracelit-components/common/bracelit-image-preview';

import { BracelitCropperComponent } from './bracelit-cropper/bracelit-cropper.component';


/** Possible display modes fot the File Uploader. */
declare type BracelitFileUploaderDisplayModes = 'table' | 'circle' | 'square' | 'compound' | 'mobile';

/**
 * BracelitFileUploader, the input for file uploading. Support multiple modes: table, circle, square, mobile or compound.
 */
@Component({
    selector: 'bracelit-file-uploader',
    templateUrl: './bracelit-file-uploader.component.html',
    styleUrls: ['./bracelit-file-uploader.component.scss'],
    standalone: false
})
export class BracelitFileUpLoaderComponent implements OnInit, OnChanges {
  /** The id of the BracelitFileUpLoaderComponent. */
  @Input() id: string = 'id-' + Math.floor((Math.random() * 1000) + 1);
  /** The Form Control. */
  @Input() bracelitFormControl?: AbstractControl | UntypedFormControl | UntypedFormArray;
  /** The uploadUrl. */
  @Input() uploadUrl = 'files';
  /** The downloadUrl. */
  @Input() downloadUrl = 'files';
  /** The deleteUrl. */
  @Input() deleteUrl = 'files';
  /**
   * Sets the displaying of the documents uploaded, if set to true, a preview of the document (meant for images)
   * will be showed at the table of documents
   */
  @Input() preview = false;
  /** Sets the maximum number of files that is possible to upload, default Number.POSITIVE_INFINITY. */
  @Input() maxNumberOfFiles: number = Number.POSITIVE_INFINITY;
  /** Sets the max uploads for the upload method. */
  @Input() maxUploads: number = Number.POSITIVE_INFINITY;
  /**
   * Types of files accepted,
   * put the 'type' at the input field, e.g.
   *    <bracelit-file-uploader [allowedFileFormats]="['image/jpeg', 'text/plain']"></bracelit-file-uploader>
   * '*' All formats
   * 'image/jpeg','image/png','image/gif' (.jpg, .jpeg, .png, .gif, image formats)
   * 'image/svg+xml', (.svg image format)
   * 'audio/mp3', (.mp3)
   * 'video/mp4', (.mp4)
   * 'application/pdf', (.pdf)
   * 'text/plain',  (.txt)
   * 'text/csv',  (.csv)
   * 'application/msword'(.doc)
   * 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' (.docx)
   * 'application/vnd.oasis.opendocument.text' (.odt - OpenDocument text)
   * 'application/vnd.ms-excel', (.xls)
   * 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', (.xlsx)
   */
  @Input() allowedFileFormats: string[] = [];
  /** Sets the maximum size of each file in bytes, default 3000000 ~ 30Mb. */
  @Input() maxFileSize = 5000000;
  @Input() maxFileSizeTip = '';
  @Input() recommendedDimensions = '';
  /**
   * Types of displaying, with 'table' you will get a table of documents, with 'circle' the document (image)
   * uploaded will be showed in a circular preview and with 'square" you will get a preview similar as the circle
   * one but with square shape.
   */
  @Input() displayMode: BracelitFileUploaderDisplayModes = 'table';
  @Input() title = '';
  @Input() titleIcon = '';
  @Input() inputText = 'AGREGAR DOCUMENTO';
  @Input() dropBoxText = 'SUBIR DOCUMENTOS';
  @Input() dropBoxIconEnabled = true;
  @Input() category = '';
  @Input() buttonIcon = 'image-plus';
  @Input() showSize = false;
  @Input() showPages = false;
  @Input() fileTypeIcon = false;

  @Input() myFiles: SavitarFile[] = [];
  @Input() temporalFiles: SavitarFile[] = [];
  /** The help tooltip. */
  @Input() helpTooltip = '';
  /** The help icon. Usually help-circle-outline or information-outline. */
  @Input() helpIcon = 'information-outline';
  @Input() resetFiles = false;
  @Input() uploadEnabled = true;
  @Input() placeholderImageUrl = '/assets/logos/Placeholder_imgUsuario.png';
  @Input() selectImageLabel = 'Seleccionar foto';
  @Input() noFilesLabelEnabled = true;
  @Input() noFilesLabel = 'Ningún archivo seleccionado';
  @Input() rejectedFileError = 'Formato de archivo no admitido, inténtalo de nuevo.';

  @Input() maxFileSizeToast = 'Tamaño máximo superado, selecciona otro archivo.';
  @Input() maxNumberOfFilesToast = 'Ya has subido el máximo número de archivos permitidos.';
  @Input() closeText = 'Cerrar';
  @Input() backText = 'Volver';
  @Input() editButtonText = 'Editar';
  @Input() deleteEnabled = true;
  @Input() deleteButtonText = 'Eliminar';
  @Input() deleteModalObjectName = 'al archivo';
  @Input() deleteWarningText = '¡Cuidado!';
  @Input() deleteWarningDescription = 'Si confirmas esta acción se borrarán permanentemente todos los datos relativos';
  @Input() deleteToastMessage = 'Archivo eliminado con éxito';
  /** Text to show when a file is uploading. */
  @Input() uploadingFileText = 'Subiendo archivo...';
  /** Aux for refres newFiles if formControl changes. */
  @Input() refreshNewFilesOnFormControlChange = false;
  /** Check if crop is enabled. */
  @Input() cropEnabled = true;
  /** Aspect ratio (crop). */
  @Input() aspectRatio = 4 / 3;
  /** Check if maintain the aspect ratio (crop). */
  @Input() maintainAspectRatio = true;
  /** Check if round the cropper (crop). */
  @Input() roundCropper: boolean;

  newFiles: SavitarFile[] = [];
  @Output() readonly filesToAssociate: EventEmitter<SavitarFile[]> = new EventEmitter();
  @Output() readonly fileRemoved: EventEmitter<SavitarFile> = new EventEmitter();

  // Posible improvement
  // output with ¡Máximo de archivos subidos! message
  options: UploaderOptions;
  files: UploadFile[] = [];
  uploadedFiles: boolean[] = [];
  imagePreviews: string[] = [];
  rejectedFiles: UploadFile[] = [];
  nonUploadedFiles: boolean[] = [];
  uploadInput: EventEmitter<UploadInput>;
  dragOver: boolean;
  humanizeBytes: (bytes: number) => string;
  // checking auxiliar variables
  overSized = false;
  apiRoute: string = null;
  /** Uploading File control. */
  uploadingFile = false;
  queueFilesToRemove: string[] = [];

  /**
   * Constructor.
   * @param matDialog
   * @param matSnackBar
   * @param authenticationService
   * @param apiService
   */
  constructor(private matDialog: MatDialog,
              private matSnackBar: MatSnackBar,
              private authenticationService: AuthenticationService,
              private apiService: ApiService) {
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    this.apiRoute = this.authenticationService.apiRoute;

    this.authenticationService.apiRoute$.pipe(
      distinctUntilChanged()
    ).subscribe(value => {
      this.apiRoute = value;
    });
  }


  /**
   * OnInit lifecycle.
   */
  ngOnInit() {
    /**
     * Set concurrency to > 0 to allow user to upload several files at one time,
     *      the number indicates how many files are uploaded simultaneously
     * Set Types of files accepted to allow certain types of files, default = any
     */
    this.options = {
      concurrency: 1,
      // Ajusta según la documentación actual. Por ejemplo, si se debe usar allowedMimeTypes:
      allowedMimeTypes: this.allowedFileFormats,
      // Y, por ejemplo, si la propiedad se llama maxFiles:
      maxFiles: this.maxUploads,
      url: this.apiRoute + this.uploadUrl
    } as UploaderOptions;

    if (this.files.length === 0 && this.myFiles.length === 0 && this.temporalFiles.length > 0) {
      for (const temporalFile of this.temporalFiles) {
        this.myFiles.push(temporalFile);
        this.newFiles.push(temporalFile);
      }
    }

    if (this.preview) {
      for (const myFile of this.myFiles) {
        this.imagePreviews.push(myFile.url);
      }
    }

    // Crop circle init
    if (this.cropEnabled && this.displayMode === 'circle' && this.roundCropper === undefined) {
      this.roundCropper = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.myFiles) {
      if (!changes.myFiles.firstChange && this.myFiles && this.myFiles.length > 0) {
        for (const myFile of this.myFiles) {
          this.imagePreviews.push(myFile.url);
        }
      } else if (!this.myFiles) {
        this.myFiles = [];
      }
    }
    if (changes.resetFiles) {
      if (!changes.resetFiles.firstChange && (changes.resetFiles.currentValue !== changes.resetFiles.previousValue)) {
        this.newFiles = [];
      }
      this.imagePreviews = [];
      for (const myFile of this.myFiles) {
        this.imagePreviews.push(myFile.url);
      }
    }
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      this.startUpload();
      this.uploadingFile = true;
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added
      if (this.queueFilesToRemove.length > 0) {
        for (const fileToRemove of this.queueFilesToRemove) {
          this.removeFile(fileToRemove);
          this.queueFilesToRemove.splice(this.queueFilesToRemove.indexOf(fileToRemove), 1);
        }
      }
      this.uploadedFiles.push(false);
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      if (index > -1) {
        this.files[index] = output.file;
      }
    } else if (output.type === 'removed') {
      // remove file from array when removed
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
      this.uploadedFiles.splice(index, 1);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      if (output.file.responseStatus === 200 && output.file.response.file) {
        this.files.push(output.file);
        const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
        this.uploadedFiles[index] = true;
        const newFile = new SavitarFile(output.file.response.file);
        this.newFiles.push(newFile);
        this.filesToAssociate.emit(this.newFiles);
        this.addFilesToFormControl(this.newFiles);
        if (this.preview) {
          this.imagePreviews.push(newFile.url);
        }
        this.uploadingFile = false;
      }
    } else if (output.type === 'rejected' && typeof output.file !== 'undefined') {
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.rejectedFiles.push(output.file);
      setTimeout(() => {
        this.nonUploadedFiles[index] = true;
        this.uploadingFile = false;
      }, 300);
      this.queueFilesToRemove.push(output.file.id);
    }
  }

  startUpload(): void {
    if (this.uploadEnabled) {
      const event: UploadInput = {
        // headers: { Accept: '/', 'Access-Control-Allow-Origin': '*' },
        type: 'uploadAll',
        // url: 'http://ngx-uploader.com/upload',
        url: this.apiRoute + this.uploadUrl,
        method: 'POST',
        headers: {
          'ngsw-bypass': 'true'
        },
        data: {
          category: this.category,
          maxFileSize: this.maxFileSize.toString(),
          filesLimit: this.maxNumberOfFiles.toString(),
          existingFilesCount: (this.files.length + this.myFiles.length).toString()
        }
      };
      this.uploadInput.emit(event);
    }
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({type: 'cancel', id: id});
  }

  removeFile(id: string): void {
    this.uploadInput.emit({type: 'remove', id: id});
  }

  removeFileNoLoaded(i: number): void {
    this.rejectedFiles.splice(i, 1);
  }

  removeAllFilesNoLoaded(): void {
    this.rejectedFiles = [];
  }

  removeAllFiles(): void {
    this.uploadInput.emit({type: 'removeAll'});
  }

  openImagePreview(i: number) {
    this.matDialog.open(BracelitImagePreviewComponent, {
      panelClass: 'no-padding-dialog',
      data: {
        imagePreviews: this.imagePreviews,
        selectedImageIndex: i
      }
    });
  }

  checkOutput(output: UploadOutput) {
    // al seleccionar varios en el explorer el >= que this.maxNumberOfFiles es en type = 'start'
    if (output.type === 'addedToQueue') {
      if (output.file.size > this.maxFileSize) {
        output.type = 'rejected';
        this.matSnackBar.open(this.maxFileSizeToast, this.closeText, {
          duration: 6000,
        });
      }
    }
    if (output.type !== 'removed') {
      if (this.files.length >= this.maxNumberOfFiles) {
        output.type = 'rejected';
        this.matSnackBar.open(this.maxNumberOfFilesToast, this.closeText, {
          duration: 6000,
        });
      }
    }
    this.onUploadOutput(output);
  }

  onResetFiles(output: UploadOutput) {
    // output.type === 'start' if selected files are more than max number of files
    if (output.type === 'addedToQueue') {
      if (this.files.length === 1) {
        this.removeFile(this.files[0].id);
      }
      if (this.myFiles.length === 1) {
        const fileToDelete = this.myFiles[0];
        if (this.preview) {
          this.imagePreviews.splice(this.imagePreviews.indexOf(fileToDelete.url), 1);
        }
        this.myFiles.splice(this.myFiles.indexOf(fileToDelete), 1);
        this.checkOutput(output);
        this.apiService.delete(this.deleteUrl + '/' + fileToDelete.id).subscribe(() => {
        });
      } else if (this.newFiles.length === 1) {
        const fileToDelete = this.newFiles[0];
        if (this.preview) {
          this.imagePreviews.splice(this.imagePreviews.indexOf(fileToDelete.url), 1);
        }
        this.newFiles.splice(this.myFiles.indexOf(fileToDelete), 1);
        this.checkOutput(output);
        this.apiService.delete(this.deleteUrl + '/' + fileToDelete.id).subscribe(() => {
        });
      } else {
        this.checkOutput(output);
      }
    } else {
      this.checkOutput(output);
    }
  }

  downloadFile(file: SavitarFile) {
    this.apiService.download(this.downloadUrl + '/' + file.id).subscribe(
      data => {
        FileSaver.saveAs(data, file.name);
      },
      () => {
      });
  }

  deleteFile(file: SavitarFile) {
    const completeDeleteApiUrl = this.deleteUrl + '/' + file.id;
    const dialogRef = this.matDialog.open(BracelitConfirmDeleteComponent, {
      width: '500px',
      minWidth: '320px',
      autoFocus: false,
      data: {
        deleteUrlRoute: completeDeleteApiUrl,
        deleteModalObjectName: 'al archivo',
        deleteWarningText: this.deleteWarningText,
        deleteWarningDescription: this.deleteWarningDescription,
        backText: this.backText,
        deleteButtonText: 'Eliminar',
        deleteToastMessage: 'Archivo eliminado con éxito',
      }
    });
    const subDelete = dialogRef.componentInstance.postDeleteEmitter.subscribe(() => {
      if (this.preview) {
        this.imagePreviews.splice(this.imagePreviews.indexOf(file.url), 1);
      }
      this.myFiles.splice(this.myFiles.indexOf(file), 1);
      // In case of multiple selection when maxFiles = 1 the rest of files are pushed to rejectedFiles,
      // when delete the uploaded one
      // rejectedFiles should be reseted.
      if (this.maxNumberOfFiles === 1) {
        this.removeAllFilesNoLoaded();
      }
      // this.myFiles.splice(this.myFiles.indexOf(file), 1);
      this.fileRemoved.emit(file);
      this.removeFileFromFormControl(file);
    });

    dialogRef.afterClosed().pipe(delay(3000)).subscribe(() => {
      subDelete.unsubscribe();
    });
  }

  deleteNewFile(file: SavitarFile, newFile: UploadFile = null) {
    const completeDeleteApiUrl = this.deleteUrl + '/' + file.id;
    const dialogRef = this.matDialog.open(BracelitConfirmDeleteComponent, {
      width: '500px',
      minWidth: '320px',
      autoFocus: false,
      data: {
        deleteUrlRoute: completeDeleteApiUrl,
        deleteModalObjectName: this.deleteModalObjectName,
        deleteWarningText: this.deleteWarningText,
        deleteWarningDescription: this.deleteWarningDescription,
        backText: this.backText,
        deleteButtonText: this.deleteButtonText,
        deleteToastMessage: this.deleteToastMessage,
      }
    });
    const subDelete = dialogRef.componentInstance.postDeleteEmitter.subscribe(() => {
      if (this.preview) {
        this.imagePreviews.splice(this.imagePreviews.indexOf(file.url), 1);
      }
      this.newFiles.splice(this.newFiles.indexOf(file), 1);
      this.fileRemoved.emit(file);
      this.removeFileFromFormControl(file);
      if (newFile) {
        this.removeFile(newFile.id);
      }
      // In case of multiple selection when maxFiles = 1 the rest of files are pushed to rejectedFiles,
      // when delete the uploaded one
      // rejectedFiles should be reseted.
      if (this.maxNumberOfFiles === 1) {
        this.removeAllFilesNoLoaded();
      }
    });

    dialogRef.afterClosed().pipe(delay(3000)).subscribe(() => {
      subDelete.unsubscribe();
    });
  }

  /**
   * Add the files to the formControl value if is set.
   */
  addFilesToFormControl(files: SavitarFile[]) {
    if (this.bracelitFormControl) {
      // Multiple files
      if (this.bracelitFormControl instanceof UntypedFormArray) {
        while (this.bracelitFormControl.length > 0) {
          this.bracelitFormControl.removeAt(0);
        }
        for (const file of files) {
          this.bracelitFormControl.push(new UntypedFormControl(file.id));
        }
      } else {
        // Only 1 file
        this.bracelitFormControl.setValue(files[0].id);
      }
    }
  }

  /**
   * Remove the file from the formControl value if is set.
   */
  removeFileFromFormControl(fileToRemove: SavitarFile) {
    if (this.bracelitFormControl) {
      // Multiple files
      if (this.bracelitFormControl instanceof UntypedFormArray) {
        for (let i = 0; i < this.bracelitFormControl.value.length; i++) {
          if (this.bracelitFormControl.value[i] === fileToRemove.id) {
            this.bracelitFormControl.removeAt(i);
          }
        }
      } else {
        // Only 1 file
        this.bracelitFormControl.setValue(null);
      }
    }
  }

  /**
   * Deletes newFiles from database.
   */
  directDeleteNewFiles() {
    const ids = [];
    for (const newFile of this.newFiles) {
      ids.push(newFile.id);
    }
    this.apiService.delete(this.deleteUrl, {keys: ids}).subscribe(() => {
    });
  }

  /**
   * Opens the crop dialog.
   * @param event
   */
  openCrop(event: any): void {
    // Provisional clear
    if (this.files.length === 1) {
      this.removeFile(this.files[0].id);
    }
    if (this.myFiles.length === 1) {
      const fileToDelete = this.myFiles[0];
      if (this.preview) {
        this.imagePreviews.splice(this.imagePreviews.indexOf(fileToDelete.url), 1);
      }
      this.myFiles.splice(this.myFiles.indexOf(fileToDelete), 1);
      this.apiService.delete(this.deleteUrl + '/' + fileToDelete.id).subscribe(() => {
      });
    } else if (this.newFiles.length === 1) {
      const fileToDelete = this.newFiles[0];
      if (this.preview) {
        this.imagePreviews.splice(this.imagePreviews.indexOf(fileToDelete.url), 1);
      }
      this.newFiles.splice(this.myFiles.indexOf(fileToDelete), 1);
      this.apiService.delete(this.deleteUrl + '/' + fileToDelete.id).subscribe(() => {
      });
    }
    // Check type
    if (event.target.files[0] &&
      this.allowedFileFormats.find((type: string) => type === event.target.files[0].type) !== undefined) {
      // Open Crop dialog
      const dialogRef = this.matDialog.open(BracelitCropperComponent, {
        data: {
          event: event,
          aspectRatio: this.aspectRatio,
          maintainAspectRatio: this.maintainAspectRatio,
          roundCropper: this.roundCropper,
          maxFileSize: this.maxFileSize,
        }
      });

      dialogRef.afterClosed().subscribe((fileToUpload) => {
        if (fileToUpload && fileToUpload.croppedFile) {
          this.uploadingFile = true;
          const formData = new FormData();
          formData.append('category', this.category);
          formData.append('maxFileSize', this.maxFileSize.toString());
          formData.append('filesLimit', this.maxNumberOfFiles.toString());
          formData.append('existingFilesCount', (this.files.length + this.myFiles.length).toString());
          formData.append('file', fileToUpload.croppedFile, fileToUpload.croppedFileName);
          this.apiService.post('files', formData).subscribe((response) => {
            this.files.push(response.file);
            const index = this.files.findIndex(
              file => typeof response.file !== 'undefined' && file.id === response.file.id
            );
            this.uploadedFiles[index] = true;
            const newFile = new SavitarFile(response.file);
            this.newFiles.push(newFile);
            this.filesToAssociate.emit(this.newFiles);
            this.addFilesToFormControl(this.newFiles);
            if (this.preview) {
              this.imagePreviews.push(newFile.url);
            }
            this.uploadingFile = false;
          });
        }
      });
    } else {
      // File not allowed
      this.matSnackBar.open(this.rejectedFileError, this.closeText, {
        duration: 6000,
      });
    }
  }
}
