import { Pipe, PipeTransform } from '@angular/core';

/**
 * To use pipe:
 *
 * @example
 *
 * // custom alt string
 * {{patient.surname | bracelitAlt: 'ddkfk'}}
 * // default alt string
 * {{patient.surname | bracelitAlt}}
 */
@Pipe({
    name: 'bracelitAlt',
    standalone: false
})
export class BracelitAltPipe implements PipeTransform {

  /**
   * Pipe transform.
   * @param value
   * @param altString
   */
  transform(value: any, altString: string = '-'): any {
    if (value) {
      return value;
    } else {
      return altString;
    }
  }
}
