import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * BracelitSafeHtml pipe, sanitizes the html.
 */
@Pipe({
    name: 'bracelitSafeHtml',
    standalone: false
})
export class BracelitSafeHtmlPipe implements PipeTransform {
  /**
   * Constructor.
   * @param sanitized
   */
  constructor(private sanitized: DomSanitizer) {
  }

  /**
   * Pipe transform.
   * @param value
   */
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}
